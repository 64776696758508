import React, { Fragment } from 'react';

export const InStock = (values) => {
    const {message = '', onClick, loading, errors} = values;
    const {variantId} = values.props;
    return (
        <div className="button" onClick={() => onClick(variantId)} style={{display: 'inline-block'}}>
        {message}
        {loading &&
            <i className="icon icon-loading"></i>
        }
        </div>
    )
};