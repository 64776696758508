import React from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';
import Foundation from 'foundation-sites';
import Loadable from 'react-loadable';
import { Provider } from 'react-redux';
import { applyMiddleware, bindActionCreators, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';
import app from './reducers'
import { get, post, remove } from './Services/http';
import { add as addToCart, reorder } from './Actions/Cart.action';
import { acceptCookies, showMore } from './Actions/CookiesCTA.action';
import { addSsnToCustomerDetails as checkoutSSN } from './Actions/Checkout.action';
import { addToBackInStock } from './Actions/InStock.action';
import MiniCartContainer from './Containers/MiniCart.container';
import QuickSearchContainer from './Containers/QuickSearch.container';
import NavigationContainer from './Containers/Navigation.container';
import FacetedSearchContainer from './Containers/FacetedSearch.container';
import FacetedSearchCompactContainer from './Containers/FacetedSearchCompact.container';
import NotificationContainer from './Containers/Notification.container';
import CookiesCTAContainer from './Containers/CookiesCTAContainer';
import BrandFacetedSearchCompactContainer from './Containers/BrandFacetedSearchCompact.container';
import InStockContainer from './Containers/InStock.container';
import { 
    setImages as setProductImageSlideShows, 
    show as showProductImageSlideShow, 
    setCurrentIndex as setProductImageSlideShowIndex 
} from './Actions/LightboxImages.action';
import DynamicComponent from './Components/DynamicComponent';
import { translate } from './Services/translation';

window.__litium = window.__litium || {};
const preloadState = window.__litium.preloadState || {};
const store = createStore(app, preloadState, composeWithDevTools(applyMiddleware(thunk)));
// register redux's actions so we can invoke them from vanilla JS
// usage: window.__litium.reduxActions.addToCart(...);
window.__litium = {
    ...window.__litium,
    reduxActions: bindActionCreators({
        addToCart,
        reorder,
        setProductImageSlideShows,
        showProductImageSlideShow,
        setProductImageSlideShowIndex,
        acceptCookies,
        showMore,
        checkoutSSN,
        addToBackInStock,
    } , store.dispatch),
    events: {
        onImpersonationSearch: (query = '') => {
            return get(`/api/impersonation?query=${query}`)
                .then(response => response.json());
        },
        onStartImpersonation: (userId, orgId) => {
            return post(`/api/impersonation?userid=${userId}&orgid=${orgId}`)
                .then(response => response.json());
        },
        onStopImpersonation: () => {
            return remove('/api/impersonation');
        },
        onAddToCartButtonClick: (sourceDomNode, articleNumber, quantityFieldId = null) => {
            const nodeIdToShowNotification = sourceDomNode.id = Date.now();
            window.__litium.reduxActions.addToCart({ 
                articleNumber, 
                quantity: quantityFieldId ? document.getElementById(quantityFieldId).value : 1,
                nodeIdToShowNotification,
                notificationMessage: translate('tooltip.addedtocart'),
                hash: Date.now(),
            });
        },
        onReorderClick: (orderid) => {
            const nodeIdToShowNotification = Date.now();
            if (orderid) {
                window.__litium.reduxActions.reorder({
                    orderid,
                    nodeIdToShowNotification,
                    notificationMessage: translate('tooltip.reordertocart'),
                    hash: Date.now(),
                });
            }
        },
        onSearchResultDataChange: dom => {
            let temp = document.createElement('div');
            temp.innerHTML = dom;
            const currentResult = document.querySelector("#search-result");
            const newResult = temp.querySelector("#search-result");
            let currentFacetedCompact = currentResult.querySelector('#facetedSearchCompact');
            let newFacetedCompact = newResult.querySelector('#facetedSearchCompact');
            if (!currentFacetedCompact && !newFacetedCompact) {
                try {
                    currentFacetedCompact = currentResult.querySelector('#brandFacetedSearchCompact');
                    newFacetedCompact = newResult.querySelector('#brandFacetedSearchCompact');
                } catch (ex) {
                    console.error(ex);
                }
            }
            //move facetedSearchCompact from currentResult to newResult to keep the DOM from the react component
            if (newFacetedCompact !== null && currentFacetedCompact !== null) {
                newFacetedCompact.parentNode.replaceChild(currentFacetedCompact, newFacetedCompact);
            }
            //replace currentResult with newResult
            currentResult.parentNode.replaceChild(newResult, currentResult);
        },
        onProductImageClick: ele => {
            const productImagesSelector = 'product-images';
            const productImageSelector = 'product-image';
            const closest = (el, className) => el ? (el.classList.contains(className) ? el : closest(el.parentNode, className)) : null;
            const parentNodes = ele.closest ? ele.closest(`.${productImagesSelector}`) : closest(ele, productImagesSelector);
            const images = Array.from(parentNodes.querySelectorAll(`.${productImageSelector}`)).map(img => ({src : img.dataset.src}));
            const index = images.findIndex(_ele => ele.dataset.src === _ele.src);

            if (window.__litium.constants.currentCheckoutMode !== window.__litium.constants.checkoutMode.companyCustomers) {
                window.__litium.reduxActions.setProductImageSlideShows(images);
                window.__litium.reduxActions.showProductImageSlideShow(true);
                window.__litium.reduxActions.setProductImageSlideShowIndex(index);
            } else {
                var productImageContainer = document.getElementsByClassName('product-detail__image-container')[0] || null;
                if (productImageContainer === null)
                    return;
    
                var productImage = productImageContainer.getElementsByTagName('img')[0] || null;
                if (productImage !== null) {            
                    var productImageLink = productImage.parentElement;
                    productImageLink.setAttribute('data-src', ele.dataset.src);
                    productImage.setAttribute('src', ele.dataset.src);
                    productImage.alt = ele.getAttribute('data-alt') || "";
                    productImage.title = ele.getAttribute('data-title') || "";
                }
            }
        },
        onVariantInListImageClick: ele => {
            var element = document.querySelectorAll('[data-src="'+ ele.dataset.src+'"]');
            //window.__litium.events.onProductImageClick(element[0]);

            var productImageContainer = document.getElementsByClassName('product-detail__image-container')[0] || null;
            if (productImageContainer === null)
                return;

            var productImage = productImageContainer.getElementsByTagName('img')[0] || null;
            if (productImage !== null) {            
                var productImageLink = productImage.parentElement;
                productImageLink.setAttribute('data-src', ele.dataset.src);
                productImage.setAttribute('src', ele.dataset.src);
                productImage.alt = ele.getAttribute('data-alt') || "";
                productImage.title = ele.getAttribute('data-title') || "";
            }

            var mainBubble = productImageContainer.getElementsByTagName('span')[0] || null;
            var eleClassName = ele.getAttribute('data-bubble-class');
            if (mainBubble !== null) {
                if (eleClassName === null || eleClassName === "") {
                    mainBubble.remove();
                }
                else {
                    mainBubble.innerHTML = ele.getAttribute('data-bubble-text');
                    mainBubble.className = "product-detail__bubble " + eleClassName;
                }
            }
            else if (eleClassName !== null && eleClassName !== "") {
                var span = document.createElement("SPAN");
                span.innerHTML = ele.getAttribute('data-bubble-text');
                span.className = "product-detail__bubble " + eleClassName;

                productImageContainer.appendChild(span);
            }
        },
        onAcceptCookies: () => {
            window.__litium.reduxActions.acceptCookies();
        },
        onCookieCtaShowMore: () => {
            window.__litium.reduxActions.showMore();
        },
        onInvoiceSSNCHange: (ssn) => {
            window.__litium.reduxActions.checkoutSSN(ssn);
        },
    },
};

if (document.getElementById('miniCart')) {
    ReactDOM.render(
        <Provider store={store}>
            <MiniCartContainer/>
        </Provider>,
        document.getElementById('miniCart')
    );
}
if (document.getElementById('quickSearch')) {
    ReactDOM.render(
        <Provider store={store}>
            <QuickSearchContainer/>
        </Provider>,
        document.getElementById('quickSearch')
    );
}
if (document.getElementById('navbar')) {
    ReactDOM.render(
        <Provider store={store}>
            <NavigationContainer/>
        </Provider>,
        document.getElementById('navbar')
    );
}
if (document.getElementById('facetedSearch')) {
    ReactDOM.render(
        <Provider store={store}>
            <FacetedSearchContainer/>
        </Provider>,
        document.getElementById('facetedSearch')
    );
}
if (document.getElementById('facetedSearchCompact')) {
    ReactDOM.render(
        <Provider store={store}>
            <FacetedSearchCompactContainer/>
        </Provider>,
        document.getElementById('facetedSearchCompact')
    );
}
if (document.getElementById('brandFacetedSearchCompact')) {
    ReactDOM.render(
        <Provider store={store}>
            <BrandFacetedSearchCompactContainer />
        </Provider>,
        document.getElementById('brandFacetedSearchCompact')
    );
}
if (document.getElementById('globalNotification')) {
    ReactDOM.render(
        <Provider store={store}>
            <NotificationContainer/>
        </Provider>,
        document.getElementById('globalNotification')
    );
}
if (document.getElementById('cookies-cta')) {
    ReactDOM.render(
        <Provider store={store}>
            <CookiesCTAContainer />
        </Provider>,
        document.getElementById('cookies-cta')
    );
}

if (document.getElementById('myPagePersons')) {
    const PersonList = Loadable({
        loader: () => import('./Containers/PersonList.container'),
        loading: () => <div></div>,
    });
    ReactDOM.render(
        <Provider store={store}>
            <PersonList />
        </Provider>,
        document.getElementById('myPagePersons')
    );
}
if (document.getElementById('myPageAddresses')) {
    const AddressList = Loadable({
        loader: () => import('./Containers/AddressList.container'),
        loading: () => <div></div>,
    });
    ReactDOM.render(
        <Provider store={store}>
            <AddressList />
        </Provider>,
        document.getElementById('myPageAddresses')
    );
}
if (document.getElementById('checkout')) {
    const Checkout = Loadable({
        loader: () => import('./Containers/Checkout.container'),
        loading: () => <div></div>,
    });
    ReactDOM.render(
        <Provider store={store}>
            <Checkout />
        </Provider>,
        document.getElementById('checkout')
    );
}
if (document.getElementById('lightBoxImages')) {
    const LightboxImages = Loadable({
        loader: () => import('./Containers/LightboxImages.container'),
        loading: () => <div></div>,
    });
    ReactDOM.render(
        <Provider store={store}>
            <LightboxImages/>
        </Provider>,
        document.getElementById('lightBoxImages')
    );
}

if (document.querySelectorAll('.slider').length>0) {
    const Slider = DynamicComponent({
        loader: () => import('./Components/Slider')
    });
    Array.from(document.querySelectorAll('.slider')).forEach((slider, index) => {
        const values = [...slider.querySelectorAll('.slider__block')].map(block=>{
            return ({
                image : block.dataset.image,
                url: block.dataset.url,
                actiontext: block.dataset.actiontext,
                text: block.dataset.text,
                align: block.dataset.align,
                timer: block.dataset.timer
            })
        });
        if (values.length > 0) {
            ReactDOM.render(
                <Slider values={values}/>,
                slider
            );
        }
    });
}

if (document.getElementById('back-in-stock-cta')) {
    const el = document.getElementById('back-in-stock-cta')
    ReactDOM.render(
        <Provider store={store}>
            <InStockContainer props={{variantId: el.dataset.variant}}/>
        </Provider>,
        document.getElementById('back-in-stock-cta')
    );
}

$(document).foundation();
