import React, { Fragment } from 'react';

const Navigation = ({links=[], contentLink=null }) => {
    let contentRef = React.createRef();
    let item = React.createRef();
    function toggleContent() {
        item.current.classList.toggle('navbar__item--open');
        contentRef.current.classList.toggle('navbar__content--show');
    }
    const additionClass = contentLink && contentLink.attributes ? contentLink.attributes.cssValue : null;
    const hasChildrenClass = links.length > 0 ? 'has-children' : null;

    return (
        <Fragment>
            {contentLink ? (
                <li className={(contentLink.isSelected ? "navbar__item--open" : "") + " navbar__item"} ref={item}>
                    <div className="navbar__item--link-holder">
                        <a className={`navbar__link ${hasChildrenClass || ''} ${additionClass || ''}`}
                            href={contentLink.url || '#'} dangerouslySetInnerHTML={{ __html: contentLink.name }} >
                        </a>
                        {links.length > 0 &&
                            <span className="navbar__item--icon" onClick={toggleContent}></span>
                        }
                    </div>
                    {links.length > 0 &&
                    <div className="navbar__content-wrapper">
                        <div className="navbar__content-row">
                            <ul className={(contentLink.isSelected ? "navbar__content--show" : "") + " navbar__content"} ref={contentRef}>
                                {links.length > 0 && links.map((link, i) => !link.name ? null :
                                    <Navigation links={link.links} contentLink={link} key={i} />
                                )}
                            </ul>
                        </div>
                    </div>
                    }
                </li>
            ):null}
        </Fragment>
)}

export default Navigation;