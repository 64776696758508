import React, { Fragment } from 'react';

const sameCategory = (item, index, array) => index > 0 && array[index-1].category === array[index].category || item.showAll;

const QuickSearchResult = ({ result, selectedItem, searchUrl }) => (
    <div className="quick-search-result">
        <div className="row">
        {result && result.map((item, index, array) => (
            <Fragment key={`${item.name}-${index}`}>
                {sameCategory(item, index, array) ? null :
                    <h3 className="columns small-12 quick-search-result__item quick-search-result__group-header" >{ item.category }</h3>
                }
                <div className={`columns small-12 medium-3 quick-search-result__item ${selectedItem === index ? 'quick-search-result__item--selected' : ''}`} >
                    <a className={item.showAll ? 'quick-search-result__show-all button' : `quick-search-result__link ${item.url ? '' : 'quick-search-result__link--disabled'}`} href={item.showAll ? searchUrl :item.url}>
                        {item.hasImage && item.imageSource && <img className="quick-search-result__image" src={item.imageSource} />
                        }
                        <h4>{item.name}</h4>
                    </a>
                </div>
            </Fragment>
            ))}
        </div>
    </div>
)

export default QuickSearchResult;