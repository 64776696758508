import React from 'react';
import { connect } from 'react-redux';
import {InStock} from '../Components/InStock';

import { addToBackInStock } from '../Actions/InStock.action';

const InStockContainer = props => (
    <InStock {...props} />
)

const mapStateToProps = (state, ownProps) => {
    const { inStock } = state;
    const {variantId} = ownProps;
    return {
        ...{...inStock, ...ownProps},
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onClick: (variantId) => dispatch(addToBackInStock(variantId)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InStockContainer);