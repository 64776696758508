import { 
    CHECKOUT_SUBMIT, 
    CHECKOUT_SUBMIT_ERROR, 
    CHECKOUT_SET_CUSTOMER_INFO,
    CHECKOUT_SET_CAMPAIGN_CODE,
    CHECKOUT_SET_DELIVERY,
    CHECKOUT_SET_PAYMENT,
    CHECKOUT_SET_ORDER_NOTE,
    CHECKOUT_ACCEPT_TERMS_OF_CONDITION,
    CHECKOUT_SET_PRIVATE_CUSTOMER,
    CHECKOUT_SET_PAYMENT_WIDGET,
    CHECKOUT_SET_SELECTED_COMPANY_ADDRESS,
    CHECKOUT_SET_SIGN_UP,
    CHECKOUT_SET_DROPSHIPPING_DETAILS,
    CHECKOUT_SET_IS_DROPSHIPPING,
    CHECKOUT_SET_ACCEPTED_SIGN_UP_TERMS,
    CHECKOUT_SET_SSN,
    CHECKOUT_SET_ORDER_DETAILS,
    CHECKOUT_TOGGLE_DELIVERYMETHODS,
} from '../Actions/Checkout.action';
import { error as errorReducer } from './Error.reducer';

const defaultState = { 
    payload: {
        alternativeAddress: {},
        customerDetails: {},
        selectedCompanyAddressId: null,
        selectedDeliveryMethod: {},
        selectedPaymentMethod: {},
        campaignCode: '',
        orderNote: {},
        paymentWidget: null,
        isBusinessCustomer: false,
        signUp: false,
        acceptSignUpTerms: false,
        acceptTermsOfCondition: false,
        companyName: '',
        showDropshipping: false,

        authenticated: false,
        deliveryMethods: [],
        paymentMethods: [],
        companyAddresses: [],
        dropShippingDeliveryAddress: {},
        responseUrl: '',
        cancelUrl: '',
        redirectUrl: '',
        isDropshipping: false,
    }, 
    errors: {},
    result: {}, 
    isSubmitting: false,
}

export const checkout = (state = defaultState, action) => {
    const { type, payload } = action;
    switch (type) {
        case CHECKOUT_SUBMIT_ERROR:
            return {
                ...state,
                errors: errorReducer(state.errors, action)
            }
        case CHECKOUT_SUBMIT:
            return {
                ...state,
                ...payload,
            };
        case CHECKOUT_SET_CUSTOMER_INFO:
        case CHECKOUT_SET_DROPSHIPPING_DETAILS:
        case CHECKOUT_SET_ORDER_DETAILS:
            return {
                ...state,
                payload: {
                    ...state.payload,
                    [payload.key]: {
                        ...state.payload[payload.key],
                        ...payload.data,
                    }
                },
            }
        case CHECKOUT_SET_SSN: {
            return {
                ...state,
                payload: {
                    ...state.payload,
                    customerDetails: {
                        ...state.payload.customerDetails,
                        ...payload
                    }
                }
            }
        }
        case CHECKOUT_TOGGLE_DELIVERYMETHODS:
        case CHECKOUT_SET_IS_DROPSHIPPING:
        case CHECKOUT_SET_DELIVERY:
        case CHECKOUT_SET_PAYMENT:
        case CHECKOUT_SET_ORDER_NOTE:
        case CHECKOUT_SET_PAYMENT_WIDGET:
        case CHECKOUT_SET_PRIVATE_CUSTOMER:
        case CHECKOUT_SET_SIGN_UP:
        case CHECKOUT_SET_ACCEPTED_SIGN_UP_TERMS:
        case CHECKOUT_SET_SELECTED_COMPANY_ADDRESS:
        case CHECKOUT_ACCEPT_TERMS_OF_CONDITION:
        case CHECKOUT_SET_CAMPAIGN_CODE:
            return {
                ...state,
                payload: {
                    ...state.payload,
                    ...payload,
                }
            }
        default:
            return state;
    }
}