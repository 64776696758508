import React, { Component, Fragment } from 'react';
import { translate } from '../Services/translation';

class CookiesCTA extends Component {
    constructor(props) {
        super(props);
        this.moreInfoRef = React.createRef();
        this.state = {
            ...props
        }
    }

    showMoreInfo() {
        this.props.showMore();
    }

    acceptCookies() {
        this.props.acceptCookies();
    }

    render() {
        if (this.props.acceptedCookies)
            return ('');
        return (
            <div className="cookie-cta__container row">
                <div className="cookie-cta__start-info columns small-12" dangerouslySetInnerHTML={{ __html: translate('cookie.start') }}></div>
                <div ref={this.moreInfoRef} className={'cookie-cta__more-info columns small-12 ' + (this.props.showMore ? 'show' : '')} dangerouslySetInnerHTML={{ __html: translate('cookie.more') }}></div>
            </div>
        )

    }
}

export default CookiesCTA;