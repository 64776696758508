import { get } from '../Services/http';
import { catchError } from './Error.action';

export const FACETED_SEARCH_QUERY = "FACETED_SEARCH_QUERY";
export const FACETED_SEARCH_RECEIVE = "FACETED_SEARCH_RECEIVE";
export const FACETED_SEARCH_ERROR = "FACETED_SEARCH_ERROR";
export const FACETED_SEARCH_TOGGLE_VALUE = "FACETED_SEARCH_TOGGLE_VALUE";
export const FACETED_SEARCH_TOGGLE_COMPACT = "FACETED_SEARCH_TOGGLE_COMPACT";

export const query = (queryString = '', withHtmlResult = true) => (dispatch, getState) => {
    dispatch({
        type: FACETED_SEARCH_QUERY,
        payload: {
            query: queryString,
        }
    });

    let url = withHtmlResult ? '/api/productFilter/withHtmlResult' : '/api/productFilter'
    if (queryString && queryString.trim() !== '') {
        url += `?${queryString}`;
    }
    return get(url)
        .then(response => response.json())
        .then(result => {
            result = {
                ...result,
                sortCriteria : transformSortCriteria(result.sortCriteria),
                subNavigation : transformSubNavigation(result.subNavigation),
            }
            dispatch(receive(result));
            withHtmlResult && window.__litium.events.onSearchResultDataChange(result.productsView);
        })
        .catch(ex => dispatch(catchError(ex, error => searchError(error))))
    ;
}

const transformSortCriteria = (sortCriteria) => !sortCriteria || !sortCriteria.sortingVisible ? [] : sortCriteria.sortItems;

const transformSubNavigation = subNavigation => {
    if (!subNavigation || !subNavigation.contentLinks || !subNavigation.contentLinks[0] || !subNavigation.contentLinks[0].links)
        return [];
    const rootLink = subNavigation.contentLinks[0].links;
    const subTransform = parentLinks => parentLinks.map(({name, url, isSelected, links}) => ({
        name, url, isSelected,
        links: subTransform(links || [])
    }));
    return subTransform(rootLink);
}

const submit = (facetFilters, dispatch) => {
    const filterCriteria = toFilterCriteria(facetFilters);
    const filterIds = facetFilters.map(filter => filter.id);
    const unChangedParams = (window.location.search.substr(1)||'').split('&').filter(param => {
        const [id, value] = param.split('=');
        return param.length > 0 && !filterIds.includes(id);
    });
    const q = [...unChangedParams, ...filterCriteria].join('&');
    const url = window.location.href.replace(window.location.search, '') + encodeURI(`${q?'?':''}${q}`);
    window.history.pushState('search', 'Search Page', url);

    dispatch(query(q));
}

const toFilterCriteria = groups => groups
    .map(group => group.selectedOptions
        .filter(val => val.length > 0)
        .map(val => `${group.id}=${val}`)
    )
    .reduce((flat, current) => [...flat, ...current], []);

/**
 *
 * @param filter : the group has changed facet
 * @param option : changed facet
 * @param forceSubmit : forceSubmit search or only change status. We need this field because in compact mode we only change the status and have other button to forceSubmit searching. But in desktop we change the search and forceSubmit searching immediately
 */
export const searchFacetChange = (filter = null, option = null, forceSubmit = true) => (dispatch, getState) => {
    if (filter) {
        const allFilters = getState().facetedSearch.facetFilters;
        const filterIndex = allFilters.findIndex(f => f.id === filter.id);
        const newFilter = option ? toggleFilterValue(filter, option) : {...filter, selectedOptions : [] };
        const newFilters = [
            ...allFilters.slice(0, filterIndex),
            newFilter,
            ...allFilters.slice(filterIndex + 1)
        ];
        dispatch(facetClientChanged(newFilters)); // client update state
    }
    if (forceSubmit) { // call server to forceSubmit search facet
        submit(getState().facetedSearch.facetFilters, dispatch);
    }
}

const toggleFilterValue = (filter, option) => {
    const { singleSelect, selectedOptions } = filter;
    const optionIndex = selectedOptions.indexOf(option.value);
    const newSelectedOptions = optionIndex < 0
        // not yet selected, select it
        ? singleSelect ? [option.value] : [...selectedOptions, option.value]
        // selected, deSelect  it
        : [...selectedOptions.slice(0, optionIndex), ...selectedOptions.slice(optionIndex + 1)];
    return { ...filter, selectedOptions: newSelectedOptions};
}

export const facetClientChanged = facetFilters => ({
    type: FACETED_SEARCH_TOGGLE_VALUE,
    payload: {
        facetFilters,
    }
})

export const toggleVisibleDropdownMenu = ({id}) => ({
    type: FACETED_SEARCH_TOGGLE_COMPACT,
    payload: {
        visibleDropdownMenu: id,
    }
})

export const searchError = error => ({
    type: FACETED_SEARCH_ERROR,
    payload: {
        error,
    }
})

export const receive = (payload) => {
    return {
        type: FACETED_SEARCH_RECEIVE,
        payload,
    }
}