import React, { Component } from 'react';
import QuickSearchResult from './QuickSearchResult';
import { translate } from '../Services/translation';

class QuickSearch extends Component {
    constructor(props) {
        super(props);
        this.searchInputRef = React.createRef();
    }
    focusOnInput() {
        setTimeout(() => {
            this.searchInputRef.current && this.searchInputRef.current.focus();
        }, 0);
    }
    
    render () {
        const { query, result, showResult, showFullForm, onSearch, onBlur, onKeyDown, toggleShowFullForm, selectedItem, searchUrl } = this.props;
        return (
            <div className="quick-search" role="search">
                <a className="quick-search__link--block" onClick={e => {toggleShowFullForm(); this.focusOnInput()}}>
                    <span className="quick-search__title">{translate('general.search.title')}</span>
                    <i className="quick-search__icon"></i>
                </a>
                <div className={`quick-search__form ${showFullForm ? 'quick-search__form--force-show' : ''}`} role="search">
                    <div className="quick-search__padding-row"><i className="quick-search__close-icon" onClick={e => toggleShowFullForm()}></i></div>
                    <div className="quick-search__scroller">
                        <div className="columns small-12">
                            
                            <div className="quick-search__input-wrap">
                                <input className="quick-search__input" type="search" placeholder={translate('general.search.placeholder')}
                                    autoComplete="off" value={query} onChange={event => onSearch(event.target.value)}
                                    onKeyDown={event => onKeyDown(event, { searchUrl })} ref={(input) => { this.searchInput = input; }}
                                    onBlur={() => onBlur()}
                                    ref={this.searchInputRef} />
                                <button className="quick-search__submit-button" type="submit">
                                    <i className="quick-search__submit-icon"></i>
                                    <span className="quick-search__submit-title">{translate('general.search.button')}</span>
                                </button>
                            </div>
                        </div>
                        <div className="columns small-12">
                            {showResult && <QuickSearchResult result={result} selectedItem={selectedItem} searchUrl={searchUrl} />}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default QuickSearch;