import { NAVIGATION_RECEIVE, NAVIGATION_HIDE, NAVIGATION_SHOW } from '../Actions/Navigation.action';

export const navigation = (state = { menu: [], visible: false }, action) => {
    switch (action.type) {
        case NAVIGATION_RECEIVE:
            return {
                ...state,
                ...action.payload,
            };
        case NAVIGATION_HIDE:
            return {
                ...state,
                ...action.payload,
            };
        case NAVIGATION_SHOW:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
}