import React from 'react';
import { connect } from 'react-redux';
import MiniCart from '../Components/MiniCart';
import { toggle } from '../Actions/Cart.action';

import { reloadPayment } from '../Actions/Checkout.action';
import { update as updateOrderRowQuantity } from '../Actions/Cart.action';

const MiniCartContainer = props => (
    <MiniCart {...props} />
)

const mapStateToProps = state => {
    const { cart } = state;
    return {
        ...cart,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        toggle: () => dispatch(toggle()),
        removeOrderRow: (rowId) => dispatch(updateOrderRowQuantity(rowId, 0)).then(() => dispatch(reloadPayment())),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MiniCartContainer);