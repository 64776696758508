import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import CookiesCTA from '../Components/CookiesCTA';
import { checkCookie, acceptCookies, showMore } from '../Actions/CookiesCTA.action';

class CookiesCTAContainer extends Component {
    componentDidMount() {
        this.props.checkCookie();
    }

    render() {
        return (
            <CookiesCTA {...this.props} />
        );
    }
}

const mapStateToProps = state => {
    return {
        acceptedCookies: state.cookiesCTA.acceptedCookies,
        showMore: state.cookiesCTA.showMore
    }
}

const mapDispatchToProps = dispatch => {
    return {
        checkCookie: () => dispatch(checkCookie()),
        acceptCookies: () => dispatch(acceptCookies()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CookiesCTAContainer);